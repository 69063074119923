import React from "react"

import styles from "./base-details.module.scss"
import skillsStyles from "./skills.module.scss"

import level1 from "../../../images/skills/level1.png"
import level2 from "../../../images/skills/level2.png"
import level3 from "../../../images/skills/level3.png"
import level4 from "../../../images/skills/level4.png"

const Skills = () => {
  return (
    <div className={styles.container}>
      <div className={skillsStyles.container}>
        <img src={level1} alt="React.js, Sass, JavaScript, CSS3, HTML5" />
        <img src={level2} alt="Redux, Git, Webpack, Jest, Protractor" />
        <img src={level3} alt="Backstop.js, Jenkins, Docker, Express" />
        <img
          src={level4}
          alt="Gatsby, Storybook, Contentful, Netlify, Heroku"
        />
      </div>
    </div>
  )
}

export default Skills
